import React, { useEffect, useCallback } from 'react';
import './Privacy.css';
import Navbar from '../../components/navigation/Navbar';
import Color from '../../css/Colors'
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    return(
        <div className='Privacy-Policy'>
            <Navbar />
            <div className="row" style={{marginTop:20}}>
                <div className='col-12 col-sm-12 col-md-12'>
                    <div class="d-flex justify-content-center">
                        <div className='d-flex elevated-container m-5 p-5 ' style={{alignItems:'start', flexDirection:'column'}}>
                            <img height={50} src="/Affix..png" />
                            <br/>
                            <br/>
                            <h4 style={{color:Color.mediumseagreen}}>PRIVACY POLICY FOR AFFIX CARD</h4>
                            <hr style={{width:'100%',textAlign:'left',marginLeft:0}} />
                            <div className='row'>
                                <p>This Privacy Policy explains how Affix Card collects, uses, and shares information when you use our
                                mobile application (the "App") that uses Firebase Realtime Database. By using the App, you agree to
                                the terms and conditions described in this Privacy Policy.</p>
                            </div>
                            <br/>
                            <h5>Information We Collect</h5>
                            <div className='row'>
                                <p>Affix Card may collect Personal Information such as your name, email address, phone number, and
                                any other information you choose to provide when using the App. Additionally, we may collect non-
                                personal information such as your device type, operating system, and usage statistics.</p>
                            </div>
                            <br/>
                            <h5>How We Use Your Information</h5>
                            <div className='row'>
                                <p>Affix Card uses your Personal Information solely for the purpose of providing services offered by the
                                App. We do not use your Personal Information for any other purpose.</p>
                                <p>If you wish to delete your account please navigate to the following page: <Link to="/user-deletion">Account Deletion</Link></p>
                            </div>
                            <div className='row'>
                                <p>The non-personal information collected by the App may be used to analyse the usage of the App and
                                improve our services.</p>
                            </div>
                            <br/>
                            <h5>Security</h5>
                            <div className='row'>
                                <p>Affix Card takes security seriously and utilizes Firebase Realtime Database and authentication
                                security integrity protocols to ensure your Personal Information is protected from unauthorized
                                access, disclosure, or misuse. However, we cannot guarantee the security of your information.</p>
                            </div>
                            <br/>
                            <h5>How We Share Your Information</h5>
                            <div className='row'>
                                <p>Affix Card does not share your Personal Information with third parties for marketing purposes. We
                                may share your Personal Information with service providers who assist in operating the App or
                                provide related services. These service providers are bound by confidentiality agreements and are
                                prohibited from using your Personal Information for any other purpose.</p>
                            </div>
                            <div className='row'>
                                <p>We may also share your information if we are required to do so by law or if we believe such action is
                                necessary to comply with legal requirements, protect our rights or the rights of others, or to prevent
                                fraud or other illegal activities.</p>
                            </div>
                            <br/>
                            <h5>Children's Privacy</h5>
                            <div className='row'>
                                <p>Affix Card does not intend for children under the age of 13 to use the App, and we do not knowingly
                                collect Personal Information from children under the age of 13.</p>
                            </div>
                            <br/>
                            <h5>Changes to this Privacy Policy</h5>
                            <div className='row'>
                                <p>Affix Card reserves the right to update or modify this Privacy Policy at any time. Any changes will be
                                effective immediately upon posting the updated Privacy Policy on the App. Your continued use of the
                                App after any such changes constitutes your acceptance of the updated Privacy Policy.</p>
                            </div>
                            <br/>
                            <h5>Contact Us</h5>
                            <div className='row'>
                                <p>If you have any questions about this Privacy Policy, please contact us at info@affixcard.com.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default PrivacyPolicy;