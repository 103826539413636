import { getAuth, onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { fetchCompanyUsersData, fetchItemData, fetchItemsFromList, fetchListAdminDataPage } from '../../components/services/firebase-controller';
import DataTable from 'react-data-table-component';
import Color from '../../css/Colors';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { getDatabase, ref, set } from 'firebase/database';


const Users = () => {
  // Get a reference to the database
  const dbRef = ref(getDatabase());

  const [usersList, setUsersList] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [fetchedData, setFetchedData] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [userIsAdmin, setUserIsAdmin] = useState(false)
  const [currentUserEmail, setCurrentUserEmail] = useState(null);

  const columns = [
    {
        name: 'UID',
        cell: row => `${row.uid.slice(0, 20)}...`,
        wrap: true,
        hide: 'lg',
    },
    {
        name: 'First Name',
        selector: row => row.firstName,
        sortable: true,
    },
    {
        name: 'Last Name',
        selector: row => row.lastName,
        sortable: true,
        hide: 'lg',
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },
    {
      name: 'Role',
      selector: row => row.role,
      sortable: true,
    },
    {
      name: '',
      button: true,
      cell: row => {
        return (
        <span>
          {userIsAdmin ? 
          (<button 
            className='btn btn-small btn-rounded-red' 
            onClick={() => deleteUser(row.uid)}
            disabled>
            Delete
            </button> )
            :( 
          <button 
          className='btn btn-small btn-rounded-red' 
          onClick={() => deleteUser(row.uid)}>
          Delete
          </button>)}
        </span>
          
          
        );
      },
    },
    {
      name: '',
      cell: row => <button className='btn btn-table' onClick={() => handleResetPassword(row.email)}>Reset Password</button>,
      allowOverflow: false,
    },
  ];

  const handleResetPassword = async (email) => {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      alert(`Password reset link sent to ${email}`);
    } catch (error) {
      console.error("Error sending password reset email:", error);
      alert("Error sending password reset email");
    }
  };

  const deleteUser = async (uid) => {
    try {
      // Write the account deletion event to the Realtime Database
      const deleteDataRef = ref(dbRef, `deleteUserDataQueue/${uid}`);
      await set(deleteDataRef, {
        timestamp: Date.now(),
      });

    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  useEffect(() => {
    const auth = getAuth();

    async function getUserInfo(key) {
      try {
        await fetchItemData("users/", key).then((val)=>{    
          getCompanyInfo(val.companyId);    
          getUsers(val.companyId);
        });
      } catch (error) {
        console.log(error);
      }
    }

    async function getCompanyInfo(key) {
      try {
        await fetchItemData("companies/", key).then((val)=>{        
          setCompanyData(val);
        });
      } catch (error) {
        console.log(error);
      }
    }

    async function getUsers(key) {
      try {
        await fetchCompanyUsersData(key).then(async (val) => {
          const usersData = await fetchItemsFromList('users/', val.data);

          // Add role to each user. If the user email matches the current user email, then set role to 'Admin'
          const usersDataWithRole = usersData.map(user => {
            return {
              ...user,
              role: user.email === currentUserEmail ? 'Admin' : 'User'
            };
          });

          setUsersList(usersDataWithRole);
          setUserCount(val.data.length);
          setFetchedData(true);
        });
      } catch (error) {
        console.log(error);
      }
    }

    onAuthStateChanged(auth, (user) => {
      if(user)
      {
        setCurrentUserEmail(user.email);  // Store current user's email
        getUserInfo(auth.currentUser?.uid);
        user.getIdTokenResult()
        .then((idTokenResult) => {
          if (!!idTokenResult.claims.admin) {
            console.log('User is an admin');
          } else {
            console.log('User is not an admin');
          }
        })
        .catch((error) => {
          console.log(error);
        });
        }
    });
  }, [currentUserEmail]); 

  return (
    <div className="users">
      <div className='row'>
        <h3>Employees</h3>
      </div>
      {fetchedData ? (
        <div>
          <div className='row m-1'>
            Number of employees under {companyData?.companyName} - {userCount}
          </div>
        
        <DataTable
            columns={columns}
            data={usersList}
            pagination
            highlightOnHover
            paginationRowsPerPageOptions={[10,15]}
            responsive
        />
        </div>
      ) : (<div style={{marginTop:50, justifyContent: 'center', display: 'flex'}}><Spinner color={Color.mediumseagreen} size={20}/></div>)}
    </div>
  );
};

export default Users;