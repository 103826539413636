import React, { useState, useEffect, Component} from 'react';
import { render } from "react-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";

function ServerInputField({ value, onSave, maxLength, label, name, type = 'text',delay = 1000, ...rest }) {
    const [inputValue, setInputValue] = useState(value);
    const [isSaving, setIsSaving] = useState(false);
  
    const handleChange = async (e) => {
        setInputValue(e.target.value);
        setIsSaving(true);
        try {
          await onSave(e.target.value);
          setTimeout(() => {
            setIsSaving(false);
          }, delay); // Replace 1000 with the desired delay time in milliseconds
        } catch (error) {
          console.error(error);
          setIsSaving(false);
        }
      };
  
    return (
      <div className="form__group field">
        <input
          type={type}
          className="form__field"
          placeholder={label}
          name={name}
          id={name}
          value={inputValue}
          maxLength={maxLength}
          onChange={handleChange}
          {...rest}
        />
        {isSaving && <div className="loading"><Spinner color="#32c384" size={12} /></div>}
        <label htmlFor={name} className="form__label">
          {label}
        </label>
      </div>
    );
  }
  

export default ServerInputField;
