import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../components/services/authprovider';
import './admin.css'
import { Route, Routes, Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { fetchItemData, signOutUser } from '../../components/services/firebase-controller';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Color from '../../css/Colors';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";


const Admin = () => {
    const isAdmin = useContext(AuthContext);
    const [fetchedData, setFetchedData] = useState(false);
    const [companyData, setCompanyData] = useState({});
    const location = useLocation(); // once ready it returns the 'window.location' object
    const navigate = useNavigate();

    const [url, setUrl] = useState(null);
    useEffect(() => {
        const auth = getAuth();

        async function getUserInfo(key) {
            try {
                await fetchItemData("users/", key).then((val)=>{    
                getCompanyInfo(val.companyId);    
                });
            } catch (error) {
                console.log(error);
            }
        }

        async function getCompanyInfo(key) {
            try {
                await fetchItemData("companies/", key).then((val)=>{        
                setCompanyData(val);
                setFetchedData(true);
                });
            } catch (error) {
                console.log(error);
            }
        }

        onAuthStateChanged(auth, (user) => {
            if(user)
            {
              getUserInfo(auth.currentUser?.uid)
            }
          });

        setUrl(location.pathname);
      }, [location]);

      const signOut = async () => {
        await signOutUser();
        navigate('/', { replace: true });
      }

    return (
    
    <div className="container-fluid">
        {isAdmin ? (
        <div className="row flex-nowrap">
            <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 admin-sidebar">
                <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                {fetchedData ? (
                    <span>
                        <img className="w-100" src={companyData?.image} />
                        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                            <li>
                                <Link to="/admin/dashboard" className={"nav-link px-0 align-middle" + (url === "/admin/dashboard" ?" active" : "")}>
                                    <i className="fa-solid fa-house"></i> <span className="ms-1 d-none d-sm-inline">Dashboard</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/users" className={"nav-link px-0 align-middle" + (url === "/admin/users" ?" active" : "")}>
                                    <i className="fa-solid fa-users"></i> <span className="ms-1 d-none d-sm-inline">Employees</span> 
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/activations" className={"nav-link px-0 align-middle" + (url === "/admin/activations" ?" active" : "")}>
                                    <i className="fa-solid fa-key"></i> <span className="ms-1 d-none d-sm-inline">Activations</span> 
                                </Link>
                            </li>
                        </ul>
                        <hr />
                        <div className="dropdown pb-4">
                            <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownAdmin" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="d-none d-sm-inline mx-1">Admin</span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownAdmin">
                                <li><a className="dropdown-item" href="#" onClick={signOut}>Sign out</a></li>
                            </ul>
                        </div>
                    </span>): 
                    (<div style={{marginTop:50, marginLeft:50, justifyContent: 'center', display: 'flex'}}><Spinner color={Color.mediumseagreen} size={16}/></div>)}
                </div>
            </div>
            <div className="col py-3">
            <Outlet />
            </div>
        </div>
        ):(<div>
            <p>403 - Unauthorized</p>
        </div>)}
    </div>
  );
};

export default Admin;