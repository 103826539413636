// Import required Firebase Realtime Database and Authentication methods
import { getDatabase, ref, child, get, set, update, query, orderByKey, orderByChild, startAt, equalTo, limitToFirst, push, remove } from "firebase/database";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

// Import your Firebase configuration
import dbReference from "../../firebase";

// Get a reference to the database
const dbRef = ref(getDatabase());

// Get the Firebase Authentication instance
const auth = getAuth();

// Fetch a list of data at a given path
export const fetchListData = async (path) => {
  // Check if path is provided
  if (!path) {
    throw new Error("Invalid arguments provided.");
  }

  try {
    // Get a snapshot of the data at the specified path
    const snapshot = await get(ref(dbRef, path));

    const dataList = [];

    // If snapshot exists, loop through each child snapshot and add its value to dataList
    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        const childData = childSnapshot.val();
        dataList.push(childData);
      });
    }

    return dataList;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Function to remove an item in the Firebase Realtime Database
export const removeItem = async (path, key) => {
  // Check if path and key are provided
  if (!path || !key) {
    throw new Error("Invalid arguments provided.");
  }

  // Get a reference to the Firebase Realtime Database
  const db = getDatabase();

  // Get a reference to the item to be removed using the path and key
  const itemRef = ref(db, `${path}/${key}`);

  try {
    // Attempt to remove the item
    await remove(itemRef);

    // If successful, log a success message
    console.log("Item removed successfully.");
  } catch (error) {
    // If an error occurs, log the error and re-throw it to be handled by the caller
    console.error("Error removing item: ", error);
    throw error;
  }
};

export const fetchListDataPage = async (path, pageSize = 10, startAtKey = null) => {
  if (!path) {
    throw new Error("Invalid arguments provided.");
  }

  try {
    let query = query(
      ref(dbReference, path),
      orderByKey(),
      startAtKey ? startAt(startAtKey) : null,
      limitToFirst(pageSize + 1)
    );

    const snapshot = await get(query);

    const dataList = [];

    if (snapshot.exists()) {
      let count = 0;
      snapshot.forEach((childSnapshot) => {
        if (count < pageSize) {
          const childData = childSnapshot.val();
          dataList.push(childData);
        }
        count++;
      });

      const lastKey = snapshot.numChildren() > pageSize ? dataList[dataList.length - 1].uid : null;

      return { data: dataList, lastKey };
    }

    return { data: [], lastKey: null };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchListAdminDataPage = async (path, companyId, pageSize = 10, startAtKey = null) => {
  if (!path || !companyId) {
    throw new Error("Invalid arguments provided.");
  }

  try {
    const baseConstraints = [
      ref(dbReference, path),
      orderByChild('companyId'),
      equalTo(companyId),
      limitToFirst(pageSize + 1)
    ];

    if (startAtKey) {
      baseConstraints.push(startAt(startAtKey));
    }

    const queryRef = query(...baseConstraints);

    const snapshot = await get(queryRef);  

    const dataList = [];

    if (snapshot.exists()) {
      let count = 0;
      snapshot.forEach((childSnapshot) => {
        if (count < pageSize) {
          const childData = childSnapshot.val();
          dataList.push(childData);
        }
        count++;
      });

      const lastKey = count > pageSize ? dataList[dataList.length - 1].uid : null;

      return { data: dataList, lastKey };
    }

    return { data: [], lastKey: null };
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const fetchCompanyUsersData = async (companyId) => {
  if (!companyId) {
    throw new Error("Invalid arguments provided.");
  }

  try {
    const path = `companies/${companyId}/users`;

    const baseConstraints = [
      ref(dbReference, path)
    ];

    const queryRef = query(...baseConstraints);

    const snapshot = await get(queryRef);

    const userIds = [];

    if (snapshot.exists()) {
      const usersDataObj = snapshot.val(); // Get the data object
      const keys = Object.keys(usersDataObj); // Extract the keys (user IDs)

      for (const userId of keys) {
        userIds.push(userId);
      }

      return { data: userIds };
    }

    return { data: [] };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchItemsFromList = async (path,keys) => {
  try {
    const dataList = [];
    for (const key of keys) {
      const snapshot = await get(ref(dbReference, `${path}/${key}`));
      if (snapshot.exists()) {
        dataList.push(snapshot.val());
      }
    }
    return dataList;
  } catch(error)
  {
    console.error(error);
  }
  
};


export const fetchItemData = async (path, key) => {
    try {
      const snapshot = await get(child(dbRef, `${path}/${key}`));
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        throw new Error(`Failed to fetch data for ${path}/${key}`);
      }
    } catch (error) {
      console.error(error);
      throw error; // re-throw the error to be handled by the caller
    }
};

export const updateItemData = async (id, key, data) => {
  if (!id || !key || !data) {
    throw new Error("Invalid arguments provided.");
  }

  try {
    const updateData = {};

    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        updateData[`${id}/${key}/${prop}`] = data[prop];
      }
    }

    await update(dbRef, updateData);
    return Promise.resolve();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const firebasePush = dbRef.push;

export const writePushData = async (path, data) => {
  try {
    const dataRef = ref(dbRef, path);
    const newDataRef = await push(dataRef);
    const newKey = newDataRef.key;

    const { uid, ...newData } = data;

    await set(newDataRef, newData);

    return newKey;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const writeNewData = async (path, uid, data) => {
  try {
    const dataRef = ref(dbReference, `${path}/${uid}`);

    await set(dataRef, data); // Set the new data at the specified location

    return uid;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const loginUser = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const registerUser = async (email, password, firstName, lastName, activationCode) => {

}

export const signOutUser = async () => {
    try{
        signOut(auth).then(() => {
            console.log('Signed out');
        }).catch((error) => {
            console.error(error);
        });
    } catch (error) {
      console.error(error);
      throw error; // re-throw the error to be handled by the caller
    }
}