import React, { useEffect, useCallback } from 'react';
import { deleteUser as deleteFirebaseUser, signOut, getAuth } from 'firebase/auth';
import { ref, set, getDatabase } from 'firebase/database';
import './Privacy.css';
import Navbar from '../../components/navigation/Navbar';
import Color from '../../css/Colors'
import { AuthContext } from '../../components/services/authprovider';
import { useContext } from 'react';

const UserDeletion = () => {
    const { currentUser } = useContext(AuthContext);

    const auth = getAuth();
    const dbRef = ref(getDatabase());

    const deleteUser = async () => {
        try {
          // Write the account deletion event to the Realtime Database
          const deleteDataRef = ref(dbRef, `deleteUserDataQueue/${currentUser.uid}`);
          await set(deleteDataRef, {
            timestamp: Date.now(),
          });
      
          // Delete the user account from Firebase Auth
          await deleteFirebaseUser(auth.currentUser);
      
          // Sign out the user
          await signOut(auth);
      
          console.log('User deleted successfully.');
        } catch (error) {
          console.error('Error deleting user:', error);
        }
      };

    return(
        <div className='User-Deletion'>
            <Navbar />
            <div className="row" style={{marginTop:20}}>
                <div className='col-12 col-sm-12 col-md-12'>
                    <div class="d-flex justify-content-center">
                        <div className='d-flex elevated-container m-5 p-5 ' style={{alignItems:'start', flexDirection:'column'}}>
                            <img height={50} src="/Affix..png" />
                            <br/>
                            <br/>
                            <h4 style={{color:Color.mediumseagreen}}>USER DELETION FOR AFFIX CARD</h4>
                            <hr style={{width:'100%',textAlign:'left',marginLeft:0}} />
                            <div className='row'>
                                <p>We take user data very seriously, if you wish to delete your data, note that there is no confirmation of the occurance. Once you have clicked 
                                    your data will be lost forever.
                                </p>
                            </div>
                            <br/>
                            
                            {currentUser ? (<div>
                                <h5>User Deletion Process</h5>
                                <div className='row'>
                                    <p>Simply click the button below and any data related to your account will be deleted.
                                    </p>
                                </div>
                                <div className='mx-3'><a className='btn btn-normal btn-rounded-red' href="#" onClick={deleteUser}>Delete Account</a></div>
                            </div>) : (
                            <div>
                                 <h5>User Deletion Process</h5>
                                <div className='row'>
                                    <p>You need to be logged in, in order to delete your account, once logged in, navigate back to this page.
                                    </p>
                                </div>
                            </div>)
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default UserDeletion;