import logo from './logo.svg';
import './App.css';
import firebase from './firebase'
import React, { Component, useState, useEffect, img, useCallback } from "react";
import { useSearchParams, Routes, Route } from "react-router-dom";
import Card from './pages/Card/Card';
import PrivacyPolicy from './pages/Privacy/Privacy';
import Home from './pages/Home/Home';
import Login from './pages/User/Login';
import Profile from './pages/User/Profile';
import Admin from './pages/Admin/Admin';
import Dashboard from './pages/Admin/Dasboard';
import Users from './pages/Admin/Users';
import Activations from './pages/Admin/Activations';
import UserDeletion from './pages/Privacy/UserDeletion';

function App() {

  return(
    
    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' element={<Home />} ></Route>
      <Route exact path='/card' element={<Card />}></Route>
      <Route exact path='/privacy' element={<PrivacyPolicy />}></Route>
      <Route exact path='/login' element={<Login />}></Route>
      <Route exact path='/profile' element={<Profile />}></Route>
      <Route exact path='/user-deletion' element={<UserDeletion />}></Route>
      <Route exact path='/admin' element={<Admin />}>
        <Route
          path="dashboard"
          element={<Dashboard />}
        />
        <Route
          path="users"
          element={<Users />}
        />
        <Route
          path="activations"
          element={<Activations />}
        />
      </Route>
    </Routes>
  );
}

export default App;
