import React, { useEffect, useCallback } from 'react';
import Color from '../../css/Colors'
import './Home.css';
import ThreeDCard from '../../components/3d-card/ThreeDCard';
import Navbar from '../../components/navigation/Navbar';
import Particles from "react-particles";
import { loadFull } from "tsparticles";

const Home = () => {
    useEffect(() => {
        ThreeDCard(); 
    });

    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
      }, []);
    
      const particlesLoaded = useCallback(async container => {
          await console.log(container);
      }, []);
    
    return(
        <div className='Home'>
            <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "#fff",
                    },
                },
                fpsLimit: 60,
                interactivity: {
                    events: {
                        onClick: {
                            enable: false,
                            mode: "push",
                        },
                        onHover: {
                            enable: false,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 100,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#32c384",
                    },
                    links: {
                        color: "#32c384",
                        distance: 90,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        directions: "none",
                        enable: true,
                        outModes: {
                            default: "out",
                        },
                        random: true,
                        speed: 2,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 20,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 6 },
                    },
                },
                detectRetina: true,
            }}
        />
            <Navbar />
            
            <div className="row" style={{marginTop:20}}>
                <div className='col-12 col-sm-12 col-md-5 '>
                    <div className="d-flex justify-content-center m-1 m-md-5" >
                        <div>
                            <img src='/MockUp3.png' alt="affix-card-mockup" />
                        </div>
                        
                    </div>
                </div>
                <div className='col-12 col-sm-12 col-md-7'>
                    <div class="d-flex justify-content-center">
                        <div className='elevated-container mx-0 mx-md-2 my-4 p-5'>
                            <h3>ROADMAP</h3>
                            <div class="timeline">
                                <div class="rb-container">
                                    <ul class="rb">
                                        <li class="rb-item" ng-repeat="itembx">
                                            <div class="timestamp">
                                                Launch
                                            </div>
                                            <div class="item-title">Affix Card mobile application for individual users and/or companies to create and share their digital business cards.</div>
                                        </li>

                                        <li class="rb-item" ng-repeat="itembx">
                                            <div class="timestamp">
                                                Sharing
                                            </div>
                                            <div class="item-title">Implement sharing features that allow users to easily share their digital business cards through various channels.</div>
                                        </li>

                                        <li class="rb-item" ng-repeat="itembx">
                                            <div class="timestamp">
                                                Company and User Dashboard
                                            </div>
                                            <div class="item-title">A management dashboard for companies to manage and track their employees' digital business cards.
                                            Integrate Affix Card with popular CRM systems to make it easier for companies to manage their contacts.</div>
                                        </li>

                                        <li class="rb-item" ng-repeat="itembx">
                                            <div class="timestamp">
                                                Security
                                            </div>
                                            <div class="item-title">Implement security features that allow companies to easily integrate Affix Card to act as security keycards, using NFC technology, for buildings.</div>
                                        </li>

                                        <li class="rb-item" ng-repeat="itembx">
                                            <div class="timestamp">
                                                Analytics
                                            </div>
                                            <div class="item-title">Analytics and tracking features to provide users and/or companies with insights into how their digital business cards are performing.</div>
                                        </li>

                                        <li class="rb-item" ng-repeat="itembx">
                                            <div class="timestamp">
                                                Premium
                                            </div>
                                            <div class="item-title">A premium version of Affix Card that includes additional features, such as more customization options for users and/or companies and advanced analytics.</div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Home;