import React, { useEffect, useState } from 'react';
// Import useLocation hook
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { signOutUser } from '../services/firebase-controller';

const Navbar = () => {
  const location = useLocation(); // once ready it returns the 'window.location' object

  const [loggedIn, setLoggedIn] = useState(false)

  const auth = getAuth();
  const navigate = useNavigate();

  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);

    onAuthStateChanged(auth, (user) => {
      if(user)
      {
        setLoggedIn(true);
      }
      else
      {
        setLoggedIn(false);
      }
    });
  }, [location]);

  const signOut = async () => {
    await signOutUser();
    navigate('/', { replace: true });
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="/">
        <img src="/AffixCardLogo.png" alt="affix-card-logo" />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse justify-content-center" id="navbarNavDropdown">
        <ul className="navbar-nav">
          <li className="nav-item active">
           <Link to="/" className={"nav-link topbar-link" + (url === "/" ?" active" : "")}>HOME</Link>
          </li>
          <li className="nav-item">
           <Link to="/privacy" className={"nav-link topbar-link" + (url === "/privacy" ?" active" : "")}>PRIVACY POLICY</Link>
          </li>
          { loggedIn && 
          <li className="nav-item">
           <Link to="/profile" className={"nav-link topbar-link" + (url === "/profile" ?" active" : "")}>PROFILE</Link>
          </li> 
          }
        </ul>
      </div>

      { loggedIn && <div className='mx-3'><a className='btn btn-normal btn-rounded-green' href="#" onClick={signOut}>Logout</a></div>}
      { !loggedIn && <div className='mx-2'> <Link to="/login" className='btn btn-normal btn-rounded-green'>Login</Link> </div> }
      
    </nav>
  );
};

export default Navbar;