import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { fetchItemData, fetchListAdminDataPage, removeItem, writeNewData } from '../../components/services/firebase-controller';
import DataTable from 'react-data-table-component';
import Color from '../../css/Colors';
import dayjs from 'dayjs';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";


const Activations = () => {

  const [preCompanyId, setCompanyId] = useState("");
  const [activationsList, setActivationsList] = useState({});
  const [fetchedData, setFetchedData] = useState(false);
  const [modalDaysDuration, setModalDaysDuration] = useState(0);
  const [activationCode, setActivationCode] = useState("");

  const columns = [
    {
      name: 'Activation Code',
      selector: row => row.uid,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => {
        const now = dayjs();
        const expiryDate = dayjs(row.dateCreated).add(row.durationInDays, 'days');
  
        if (now.isAfter(expiryDate)) {
          return <div style={{color:"#f22424"}}>Expired</div>;
        } else {
          const daysLeft = expiryDate.diff(now, 'day');
          return <div>{daysLeft} days left</div>;
        }
      },
      sortable: true,
    },
    {
      name: '',
      button: true,
      cell: row => {
        const now = dayjs();
        const expiryDate = dayjs(row.dateCreated).add(row.durationInDays, 'days');
        const isExpired = now.isAfter(expiryDate);
  
        return (
          <button 
            className='btn btn-small btn-rounded-red' 
            onClick={() => expireActivationCode(row.uid)}>
            {isExpired ? "Delete" : "Expire"}
          </button>
        );
      },
    },
  ];

  useEffect(()  => {
    const auth = getAuth();

    async function getUserInfo(key) {
      try {
        await fetchItemData("users/", key).then((val)=>{        
          setCompanyId(val.companyId);
          getActivations(val.companyId);
        });
      } catch (error) {
        console.log(error);
      }
    }

    async function getActivations(key) {
      try {
        await fetchListAdminDataPage("activations/",key).then(async (val) => {
          const activationsData = val.data;
          setActivationsList(activationsData);
          setFetchedData(true);
        });
      } catch (error) {
        console.log(error);
      }
    }

    onAuthStateChanged(auth, (user) => {
      if(user)
      {
        getUserInfo(auth.currentUser?.uid)
      }
    });
  }, []);

  const expireActivationCode = async (key) => {
    try {
      await removeItem('activations/', key);
    } catch (error)
    {
      console.log(error);
    }
  }

  const createActivationCode = async () => {
    try {
      const newData ={
        companyId: preCompanyId,
        dateCreated: Date.now(),
        durationInDays: modalDaysDuration, 
        uid: activationCode,
      };

      if(modalDaysDuration < 6)
      {
        throw "Duration not set";
      }

      if(activationCode === "")
      {
        throw "Code not set";
      }

      await writeNewData('activations/',activationCode, newData);
    }
    catch(error)
    {
      console.log(error);
    }
  };

  const handleFirstOption = () => {
    
  };

  const handleSecondOption = () => {
    setModalDaysDuration(28);
  };

  const dropdownOptions = [
    { label: '7 days', action: handleFirstOption },
    { label: '28 days', action: handleSecondOption },
  ];

  return (
    <div className="activations">
      <div className='row m-2'>
        <h3 className='col-11'>Activations</h3>
        <button className='btn btn-small btn-rounded-admin col-1' data-bs-toggle="modal" data-bs-target="#itemModal">New</button>
      </div>
      {fetchedData ? (
      <DataTable
            columns={columns}
            data={activationsList}
            pagination
            highlightOnHover
            paginationRowsPerPageOptions={[10,15]}
            responsive
        />
      ) : (<div style={{marginTop:50, justifyContent: 'center', display: 'flex'}}><Spinner color={Color.mediumseagreen} size={20}/></div>)}
      <div class="modal fade" id="itemModal" tabindex="-1" aria-labelledby="itemModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="itemModalLabel">New Activation</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div className='row'>
                <div className='col-3'>
                  <button type="button" onClick={() => setModalDaysDuration(7)} className={"btn btn-rounded-admin" + (modalDaysDuration === 7 ?" active" : "")}>7 Days</button>
                </div>
                <div className='col-3'>
                  <button type="button" onClick={() => setModalDaysDuration(21)} className={"btn btn-rounded-admin" + (modalDaysDuration === 21 ?" active" : "")}>21 Days</button>
                </div>
              </div>
              <div className="form__group field">
                <input type="text" className="form__field" placeholder="Activation Code" name="activationCode" id='activationCode' 
                  value={activationCode} 
                  onChange={(e) => setActivationCode(e.target.value)}  />
                  <label htmlFor="activationCode" className="form__label">Activation Code</label>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-rounded-admin-grey" data-bs-dismiss="modal">Close</button>
              <button type="button" onClick={() => createActivationCode()} class="btn btn-rounded-admin">Create</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activations;