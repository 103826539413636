const Colors = {
    whitesmoke: "#fafafa",
    gray: "#161616",
    white: "#fff",
    error: "#FF5733",
    darkslategray: "#2c3e4b",
    mediumseagreen: "#32c384",
    cornflowerblue: "#3276c3",
    mediumsealightgreen: "#11998e",
    lightgreensake: "#38ef7d",
}

export default Colors;