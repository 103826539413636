import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';



const Dashboard = () => {
  return (
    <div className="dashboard">
      HELLO
    </div>
  );
};

export default Dashboard;