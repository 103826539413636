import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../components/services/firebase-controller';
import Navbar from '../../components/navigation/Navbar';
import Color from '../../css/Colors'

const Login = () => { 
    const [isRegistering, setIsRegistering] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyActivationCode, setCompanyActivationCode] = useState("");
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");

    const login = async () => {
        try {
          await loginUser(email, password);
          navigate('/', { replace: true });
        } catch (error) {
          setErrorMessage("Username and/or password are incorrect.");
          console.log(error);
        }
      };

    return(
<div className='Login'>
        <Navbar />
        <div className='row d-flex justify-content-center'>
            <div className='col-6 col-sm-6 col-md-6'>
                <div class="d-flex justify-content-center">
                    <div className='d-flex elevated-container mx-3 p-2' style={{marginTop:80, marginBottom:50, alignItems:'center', flexDirection:'column'}}>
                        <br/>
                        {!isRegistering && <h3>LOGIN</h3>}
                        {isRegistering && <h3>REGISTER</h3>}
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12'>
                                <div className="form__group field">
                                    <input type="email" className="form__field" placeholder="Email" name="email" id='email' 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required />
                                    <label htmlFor="email" className="form__label">Email</label>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12'>
                                <div className="form__group field">
                                    <input type="password" className="form__field" placeholder="Password" name="password" id='password' 
                                    value={password} 
                                    onChange={(e) => setPassword(e.target.value)} 
                                    required />
                                    <label htmlFor="password" className="form__label">Password</label>
                                </div>
                            </div>
                        </div>
                        {isRegistering && 
                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-12'>
                                    <div className="form__group field">
                                        <input type="text" className="form__field" placeholder="FirstName" name="firstName" id='firstName' 
                                        value={firstName} 
                                        onChange={(e) => setFirstName(e.target.value)} 
                                        required />
                                        <label htmlFor="firstName" className="form__label">First Name</label>
                                    </div>
                                </div>
                            </div> }
                        {isRegistering && 
                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-12'>
                                    <div className="form__group field">
                                        <input type="text" className="form__field" placeholder="LastName" name="lastName" id='lastName' 
                                        value={lastName} 
                                        onChange={(e) => setLastName(e.target.value)} 
                                        required />
                                        <label htmlFor="lastName" className="form__label">Last Name</label>
                                    </div>
                                </div>
                            </div> }
                        {isRegistering && 
                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-12'>
                                    <div className="form__group field">
                                        <input type="text" className="form__field" placeholder="Company Activation Code" name="companyActivationCode" id='companyActivationCode' 
                                        value={companyActivationCode} 
                                        onChange={(e) => setCompanyActivationCode(e.target.value)} 
                                        required />
                                        <label htmlFor="companyActivationCode" className="form__label">Company Activation Code</label>
                                    </div>
                                </div>
                            </div> }
                        {   errorMessage !== "" && <div className='row' style={{color: Color.error, marginTop:20}}><p>{errorMessage}</p></div>}
                        {
                            !isRegistering &&
                            <div className='row' style={{marginTop:40}}>
                                <a onClick={login} className='btn btn-normal btn-rounded-green'>LOGIN</a>
                            </div>
                        }
                        {
                            isRegistering &&
                            <div className='row' style={{marginTop:40}}>
                                <a className='btn btn-normal btn-rounded-green'>REGISTER</a>
                            </div>
                        }
                        <div className='row' style={{marginTop:40}}>
                            {!isRegistering && <span><p style={{fontSize:14}}>Don't have an account? <a href="#" style={{fontSize:14}} onClick={() => {setIsRegistering(true);}}>Create one</a></p></span>}
                            {isRegistering && <span><p style={{fontSize:14}}>Already have an account?  <a href="#" style={{fontSize:14}} onClick={() => {setIsRegistering(false);}}>Sign in</a></p></span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
    
};

export default Login;