import { getDatabase } from "firebase/database";
import { initializeApp, firebase } from 'firebase/app';

let config = {
    apiKey: "AIzaSyDgpcy6lYQ4G90GFawLT0SiPm09tTL_6UY",
    authDomain: "affix-card.firebaseapp.com",
    projectId: "affix-card",
    storageBucket: "affix-card.appspot.com",
    messagingSenderId: "76871013727",
    appId: "1:76871013727:web:bdfda7a539b4b5373212ea",
    databaseURL: "https://affix-card-default-rtdb.europe-west1.firebasedatabase.app"
};

const app = initializeApp(config);
const dbReference = getDatabase(app);
export default dbReference;
